import { PrintShipmentLabelsForAddressChanges } from '@/components/dashboard/PrintShipmentLabelsForAddressChanges';
import {
  SUPPORTED_CARRIERS,
  formatCarrierName,
} from '@/utilities/external-shipments';
import { DashboardDrivers } from '@components/dashboard/DashboardDrivers';
import NumberBlock from '@components/number-block/NumberBlock';
import {
  TZ_EUROPE_LONDON,
  isInstantAfter,
  parseInstant,
  startOfDay,
  todayLocal,
} from '@packfleet/datetime';
import { Shipment } from '@packfleet/ui';
import { usePollWhenFocused } from 'hooks/usePollWhenFocused';
import groupBy from 'lodash.groupby';
import keyBy from 'lodash.keyby';
import sortBy from 'lodash.sortby';
import React, { useMemo } from 'react';
import { TZ } from 'utilities/timezone';
import Page from '../components/page/Page';
import {
  PackStatus,
  useDashboardDriversQuery,
  useDashboardQuery,
} from '../generated/graphql';

import { requireStaffMember } from '../utilities/require-staff-member';
import { Routes, linkTo } from '../utilities/routes';
import { NextPageWithLayout } from './_app';

const Dashboard: NextPageWithLayout = () => {
  const {
    data: dataDashboard,
    error: errorDashboard,
    refetch: refetchDashboard,
    loading: loadingDashboard,
  } = useDashboardQuery();
  usePollWhenFocused({ refetch: refetchDashboard, interval: 30 * 1000 });
  const {
    data: dataDrivers,
    refetch: refetchDrivers,
    error: errorDrivers,
    loading: loadingDrivers,
  } = useDashboardDriversQuery();
  usePollWhenFocused({ refetch: refetchDrivers, interval: 30 * 1000 });

  const drivers = dataDrivers?.dashboardDrivers.drivers ?? [];
  const sortedDriverWaves = useMemo(() => {
    // Only include drivers from the current day onwards
    // TODO: Move to backend
    const filteredDrivers = drivers.filter((d) =>
      d.shiftStartTime
        ? isInstantAfter(
            parseInstant(d.shiftStartTime),
            startOfDay(
              todayLocal(TZ_EUROPE_LONDON),
              TZ_EUROPE_LONDON,
            ).toInstant(),
          )
        : true,
    );

    // Sort by shiftStartTime
    const sortedDrivers = sortBy(
      filteredDrivers,
      (d) => {
        const shiftStartTime = d.shiftStartTime;
        return shiftStartTime ? parseInstant(shiftStartTime).epochSeconds : 0;
      },
      (d) => d.driver?.name,
    );

    // Group in waves
    const sortedDriverWaves = Object.entries(
      groupBy(sortedDrivers, (d) => d.shiftStartTime),
    ).map(([, drivers]) => ({
      shiftStartTime: drivers[0]?.shiftStartTime,
      drivers: sortBy(
        drivers,
        (d) => d.routeStartedAt,
        (d) => d.startETA,
        (d) => d.driver?.name,
      ),
    }));

    return sortedDriverWaves;
  }, [drivers]);

  const error = errorDashboard?.message || errorDrivers?.message;
  const dashboard = dataDashboard?.dashboard;
  const showSkeleton = loadingDashboard && !dashboard;

  const pickingProgress = drivers?.reduce<[number, number]>(
    (acc, d) => {
      acc[0] = acc[0] + (d.warehousePacksPicked ?? 0);
      acc[1] = acc[1] + (d.warehousePacksTotal ?? 0);
      return acc;
    },
    [0, 0],
  );

  const externalPacksByCarrier = keyBy(
    dashboard?.externalPacks,
    (pack) => pack.carrierCode,
  );

  return (
    <div className="flex w-full flex-col gap-4 overflow-y-auto p-x4 pb-12 md:px-8 h-screen">
      <h1 className="text-2xl md:pt-8 font-bold text-primary antialiased">
        Dashboard
      </h1>
      {error ? (
        <div className="mb-4 rounded-md bg-danger/80 p-3 text-primaryInverted">
          {error}
        </div>
      ) : null}
      <div className="flex flex-wrap gap-2">
        <NumberBlock
          title="Stops"
          loading={showSkeleton}
          data={[
            { number: dashboard?.stops.remaining, name: 'remaining' },
            {
              number: dashboard?.stops.total,
              name: 'total',
              light: true,
            },
          ]}
        />
        <NumberBlock
          title="Collections"
          loading={showSkeleton}
          data={[
            {
              number: dashboard?.collections.remaining,
              name: 'remaining',
            },
            {
              number: dashboard?.collections.total,
              name: 'total',
              light: true,
            },
          ]}
        />
        <NumberBlock
          title="Collected"
          loading={showSkeleton}
          href={linkTo(Routes.shipments, { status: PackStatus.Collected })}
          data={[
            {
              number: dashboard?.packsCollected.today,
              name: 'For Today',
              href: linkTo(Routes.shipments, {
                status: PackStatus.Collected,
                deliveryDate: todayLocal(TZ_EUROPE_LONDON).toString(),
              }),
            },
            {
              number: dashboard?.packsCollected.tomorrow,
              name: 'For Tomorrow',
              href: linkTo(Routes.shipments, {
                status: PackStatus.Collected,
                deliveryDate: todayLocal(TZ_EUROPE_LONDON)
                  .add({ days: 1 })
                  .toString(),
              }),
            },
            {
              number: dashboard?.packsCollected.later,
              name: 'For Later',
            },
          ]}
        />
        <NumberBlock
          title="In Depot"
          loading={showSkeleton}
          href={linkTo(Routes.shipments, { status: PackStatus.InDepot })}
          data={[
            {
              number: dashboard?.packsInDepot.today,
              name: 'For Today',
              href: linkTo(Routes.shipments, {
                status: PackStatus.InDepot,
                deliveryDate: todayLocal(TZ_EUROPE_LONDON).toString(),
              }),
            },
            {
              number: dashboard?.packsInDepot.tomorrow,
              name: 'For Tomorrow',
              href: linkTo(Routes.shipments, {
                status: PackStatus.InDepot,
                deliveryDate: todayLocal(TZ_EUROPE_LONDON)
                  .add({ days: 1 })
                  .toString(),
              }),
            },
            {
              number: dashboard?.packsInDepot.later,
              name: 'For Later',
            },
          ]}
        />
        {pickingProgress && pickingProgress[1] > 0 ? (
          <NumberBlock
            title="Picking"
            loading={loadingDrivers && !dataDrivers}
            data={[
              {
                number: pickingProgress[0],
                name: 'packs picked',
              },
              {
                number: pickingProgress[1],
                name: 'total',
              },
            ]}
          />
        ) : null}
        <NumberBlock
          title="Out For Delivery"
          loading={showSkeleton}
          href={linkTo(Routes.shipments, { status: PackStatus.OutForDelivery })}
          data={[
            {
              number: dashboard?.packsOutForDelivery,
              name: '',
            },
          ]}
        />
        <NumberBlock
          title="Failed"
          loading={showSkeleton}
          href={linkTo(Routes.shipments, { status: PackStatus.FailedDelivery })}
          data={[
            {
              number: dashboard?.packsFailed,
              name: '',
            },
          ]}
        />
        <NumberBlock
          title="On hold"
          loading={showSkeleton}
          href={linkTo(Routes.shipments, { status: PackStatus.OnHold })}
          data={[
            {
              number: dashboard?.packsOnHold,
              name: '',
            },
          ]}
        />
        <NumberBlock
          title="Notifications"
          loading={showSkeleton}
          data={[
            {
              number: dashboard?.notifications.late,
              name: 'late',
            },
            {
              number: dashboard?.notifications.early,
              name: 'early',
            },
          ]}
        />
        {SUPPORTED_CARRIERS.map((carrierCode) => (
          <NumberBlock
            key={carrierCode}
            title={
              <div className="flex gap-2 items-center">
                <Shipment.ExternalCarrierLogo carrierCode={carrierCode} />
                Nationwide packs
              </div>
            }
            loading={showSkeleton}
            href={linkTo(Routes.externalShipments, {
              status: PackStatus.Scheduled,
              carrierCode,
            })}
            data={[
              {
                number:
                  externalPacksByCarrier[carrierCode]
                    ?.toCollectByExternalCarrier,
                name: `to collect by ${formatCarrierName(carrierCode)}`,
                href: linkTo(Routes.externalShipments, {
                  status: PackStatus.Scheduled,
                  carrierCode,
                  collectionDate: todayLocal(TZ_EUROPE_LONDON).toString(),
                }),
                light: true,
              },
              {
                number: externalPacksByCarrier[carrierCode]?.toCollect,
                name: 'to collect',
                href: linkTo(Routes.externalShipments, {
                  status: PackStatus.Scheduled,
                  carrierCode,
                  collectionDate: todayLocal(TZ_EUROPE_LONDON).toString(),
                }),
              },
              {
                number: externalPacksByCarrier[carrierCode]?.collected,
                name: 'Collected',
                href: linkTo(Routes.externalShipments, {
                  status: PackStatus.Collected,
                  carrierCode,
                }),
              },
              {
                number: externalPacksByCarrier[carrierCode]?.inDepot,
                name: `In Hub for ${formatCarrierName(carrierCode)}`,
                href: linkTo(Routes.externalShipments, {
                  status: PackStatus.InDepot,
                  carrierCode,
                }),
              },
              {
                number:
                  externalPacksByCarrier[carrierCode]?.withExternalCarrier,
                name: `With ${formatCarrierName(carrierCode)}`,
                href: linkTo(Routes.externalShipments, {
                  status: PackStatus.ExternalCarrierCollected,
                  carrierCode,
                }),
              },
            ]}
          />
        ))}
      </div>
      <PrintShipmentLabelsForAddressChanges date={todayLocal(TZ)} />
      <div className="hidden md:block">
        <div className="pb-20">
          <div className="pt-4">
            <DashboardDrivers
              driverWaves={sortedDriverWaves}
              loading={loadingDrivers && !dataDrivers}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Dashboard.getLayout = (page) => (
  <Page title="Dashboard | Pathfinder" navbarLight bg="secondary">
    {page}
  </Page>
);

export default requireStaffMember(Dashboard);
